<template>
  <div
    class="rounded-md border px-6 py-4"
    :class="classes"
    role="alert"
  >
    <p
      v-if="title"
      class="font-medium"
      :class="`text-text-${props.type}`"
      v-text="title"
    />

    <slot />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  type: 'information' | 'success' | 'warning' | 'error'
  title?: string
}>();

const classes = computed(() => {
  switch (props.type) {
    case 'information':
      return 'text-[#1E40AF] bg-[#BFDBFE] border border-[#2563EB]';
    case 'success':
      return 'text-[#065F46] bg-[#D1FAE5] border border-[#059669]';
    case 'warning':
      return 'text-[#92400E] bg-[#FDE68A] border border-[#D97706]';
    case 'error':
      return 'text-[#B91C1C] bg-[#FECACA] border border-[#DC2626]';
  }
});
</script>
